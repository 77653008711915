import router from './router'
import useUserStore from '@/stores/user'
import { goLogin, getToken } from '@/utils/auth'

const whiteList = ['/login']

export default function doPermission() {
  router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore()
    if (getToken()) {
      if (!userStore.userInfo.id) {
        try {
          await userStore.getInfo()
          next()
        } catch (err) {
          next(false)
          const timer = setTimeout(() => {
            goLogin()
            clearTimeout(timer)
          }, 1500)
        }
      } else {
        next()
      }
    } else {
      // 没有token
      // 在免登录白名单，直接进入
      if (whiteList.includes(to.path)) {
        next()
      } else {
        next(false)
        goLogin()
      }
    }
  })
}
